// REGEXPS
export const WHITE_SPACE_REGEXP = /\s/g;
export const EMAIL_REGEXP =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const NUMERIC_REGEXP = /^\d+$/;
export const LETTERS_REGEXP = /^[a-z]+$/gi;
export const PHONE_REGEXP =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
export const URL_REGEXP =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i; // eslint-disable-line
export const IE_REGEXP = /MSIE (\d+\.\d+);/;
export const RGB_REGEXP = /^rgb/;
export const RGBA_REGEXP = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
export const NUMERIC_AND_LETTERS_REGEXP = /^[a-z0-9]+$/gi;
export const SINGLE_QUOTE_REGEXP = /^[^']*$/;
export const STRING_WITHOUT_ANY_QUOTE_REGEXP = /^[^"'`]+$/;
export const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // at least one lower/upper case and at least
// one digit
export const ALPHA_NUMERIC_REGEXP = /^[0-9a-zA-Z]+$/;
export const TWO_DECIMAL_DIGITS_AFTER_PERIOD_REGEXP = /^\d+(\.\d{0,2})?$/;

// Roles
export const REPAIRER_ROLE = 'repairer';
export const FACILITY_ROLE = 'facility';
export const FACILITY_STAFF_ROLE = 'facility-staff';

export const TECHNICIAN_SUB_ALIASES = {
  STAFF: 'repairerStaffAllIn',
  DISPATCHER: 'dispatcherAllIn',
  DISPATCHER_USER: 'technicianDispatcherAllIn',
  TOP: 'repairerAdministratorAllIn',
};

// Tasks

export const TASK_FREQUENCIES = { d: 'daily', w: 'weekly', m: 'monthly', n: 'never' };

// Statuses
export const TASK_STATUSES = {
  in_progress: 'in_progress',
  complete: 'complete',
  incomplete: 'incomplete',
};

export const SYSTEM_USER_ID = 0;

export const MAPPED_TASK_STATUSES = {
  complete: 'Complete',
  incomplete: 'Incomplete',
  in_progress: 'In progress',
};

export const NETWORK_STATUSES = {
  loading: 1,
  setVariables: 2,
  fetchMore: 3,
  refetch: 4,
  poll: 6,
  ready: 7,
  error: 8,
};

export const METERS_IN_PIXEL_FOR_O_ZOOM = 78271.484;
export const METERS_IN_MILE = 1609.344;

export const CIRCLE_ANGLE = 360;
export const LENGTH_FROM_CLUSTER_TO_MARKERS = 50;

export const SEARCH_DELAY = 2000;
export const ITEMS_AMOUNT_ON_MAP = 50;

export const BYTES_IN_MEGABYTE = 1048576;
export const FILE_SIZE_LIMIT_IN_MEGABYTES = 10;
export const IMAGE_MIMES = ['image/png', 'image/jpeg', 'image/gif'];
export const PDF_MIME = 'application/pdf';

export const LONGITUDE_INDEX = 0;
export const LATITUDE_INDEX = 1;

export const WEEK_DAYS = [
  { label: 'Monday', value: 'Mon', num: 1 },
  { label: 'Tuesday', value: 'Tue', num: 2 },
  { label: 'Wednesday', value: 'Wed', num: 3 },
  { label: 'Thursday', value: 'Thu', num: 4 },
  { label: 'Friday', value: 'Fri', num: 5 },
  { label: 'Saturday', value: 'Sat', num: 6 },
  { label: 'Sunday', value: 'Sun', num: 0 },
];

export const ALL_WEEK_DAYS = [
  {
    short: 'sun',
    label: 'Sunday',
  },
  {
    short: 'mon',
    label: 'Monday',
  },
  {
    short: 'tue',
    label: 'Tuesday',
  },
  {
    short: 'wed',
    label: 'Wednesday',
  },
  {
    short: 'thu',
    label: 'Thursday',
  },
  {
    short: 'fri',
    label: 'Friday',
  },
  {
    short: 'sat',
    label: 'Saturday',
  },
];

export const WEEK_DAY_SORTER = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const COLOR_DETERMINANT = 127.5;

export const TASK_TYPE_MAP = {
  d: 'Daily',
  w: 'Weekly',
  m: 'Monthly',
  n: 'Never',
};

export const RUN_TASK_PERSISTED_FILTER = 'runTaskFilter';
export const STICKY_FACILITY_FILTER_SETTING = 'stickyFacilityListFilter';
export const SHOULD_SENT_SMS = 'shouldSendSms';

export const GO_CHECKIT_RUN_LINK = '/app/check-list/run';
export const UPTIMEHEALTH_WIKI_URL = 'https://wiki.uptimehealth.com/';
export const JWT_TOKEN_STORAGE_KEY = 'jwt-token-storage';
export const UNIFIED_IMAGING_REFRESH_TOKEN_TIME = 60 * 5; // 5 min
export const MAX_ALLOWED_TIMES_OF_UI_REFRESH_TOKEN = 4;
export const OKTA_SET_STORAGE_DELAY = 2 * 1000;

export const TECHNICIAN_TEAM_MEMBER_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const TECHNICIAN_SUB_ACCOUNT_STATUS = {
  PENDING: 'pending',
  DECLINED: 'declined',
  CONFIRMED: 'confirmed',
};

export const TECHNICIAN_SUB_ACCOUNT_STATUS_MAPPER = {
  pending: 'Pending',
  declined: 'Declined',
  confirmed: 'Confirmed',
};

const COGNITO = 'cognito';

export const COGNITO_KEYS = {
  ACCESS_TOKEN: `${COGNITO}-access-token`,
  ID_TOKEN: `${COGNITO}-id-token`,
  REFRESH_TOKEN: `${COGNITO}-refresh-token`,
  ACCESS_TOKEN_PAYLOAD: `${COGNITO}-access-token-payload`,
  ID_TOKEN_PAYLOAD: `${COGNITO}-id-token-payload`,
};

export const CANADA_POSTAL_CODE_REGEX =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

export const LAST_LOGIN = 'last-login';
// errors
export const REQUIRED_ERROR = 'REQUIRED';
