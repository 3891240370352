import { gql } from '@apollo/client';

export const getSignedUrlForTaskFile = gql`
  query getSignedUrlForTaskFile($taskId: String!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForTaskFile(taskId: $taskId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForDeviceDocument = gql`
  query getSignedUrlForDeviceDocument($deviceId: Int, $keyName: String!, $mimeType: String!) {
    getSignedUrlForDeviceDocument(deviceId: $deviceId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForDeviceDocumentAsTechnician = gql`
  query getSignedUrlForDeviceDocumentAsTechnician(
    $facilityUserId: Int!
    $signature: String!
    $deviceId: Int!
    $keyName: String!
    $mimeType: String!
  ) {
    getSignedUrlForDeviceDocumentAsTechnician(
      facilityUserId: $facilityUserId
      signature: $signature
      deviceId: $deviceId
      keyName: $keyName
      mimeType: $mimeType
    )
  }
`;

export const getSignedUrlForDeviceFile = gql`
  query getSignedUrlForDeviceFile(
    $facilityUserId: Int
    $signature: String
    $deviceId: Int!
    $keyName: String!
    $mimeType: String!
  ) {
    getSignedUrlForDeviceFile(
      facilityUserId: $facilityUserId
      signature: $signature
      deviceId: $deviceId
      keyName: $keyName
      mimeType: $mimeType
    )
  }
`;

export const getSignedUrlForRunTaskFile = gql`
  query getSignedUrlForRunTaskFile($runTaskId: String!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForRunTaskFile(runTaskId: $runTaskId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForProfileFile = gql`
  query getSignedUrlForProfileFile($userId: Int!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForProfileFile(userId: $userId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForLogFile = gql`
  query getSignedUrlForLogFile($userId: Int!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForLogFile(userId: $userId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForProfileBusinessFile = gql`
  query getSignedUrlForProfileBusinessFile($userId: Int!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForProfileBusinessFile(userId: $userId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForProfileDocument = gql`
  query getSignedUrlForProfileDocument($userId: Int!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForProfileDocument(userId: $userId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForContractDocument = gql`
  query signedUrlForContractDocument($contractId: String!, $keyName: String!, $mimeType: String!) {
    signedUrlForContractDocument(contractId: $contractId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const fetchTaskFiles = gql`
  query fetchTaskFiles($taskId: String!) {
    fetchTaskFiles(taskId: $taskId) {
      id
      keyName
      fileName
      mimeType
    }
  }
`;

export const fetchTaskFileUrl = gql`
  query fetchTaskFileUrl($taskId: String!, $fileId: String!) {
    fetchTaskFileUrl(taskId: $taskId, fileId: $fileId)
  }
`;

export const fetchRunTaskFileUrl = gql`
  query fetchRunTaskFileUrl($runTaskId: String!) {
    fetchRunTaskFileUrl(runTaskId: $runTaskId)
  }
`;

export const getFacilityFileUrl = gql`
  query fetchFacilityFileUrl($facilityId: Int!) {
    url: fetchFacilityFileUrl(facilityId: $facilityId)
  }
`;

export const fetchFacilityDocuments = gql`
  query fetchFacilityDocuments($facilityId: Int!) {
    documents: fetchFacilityDocuments(facilityId: $facilityId) {
      id
      file {
        id
        keyName
        fileName
        mimeType
      }
    }
  }
`;

export const fetchContractDocuments = gql`
  query contractDocuments($contractId: String!) {
    documents: contractDocuments(contractId: $contractId) {
      id
      file {
        id
        keyName
        fileName
        mimeType
      }
    }
  }
`;

export const fetchFacilityDocumentFileUrl = gql`
  query fetchFacilityDocumentFileUrl($facilityId: Int!, $fileId: String!) {
    url: fetchFacilityDocumentFileUrl(facilityId: $facilityId, fileId: $fileId)
  }
`;

export const getProfileDocuments = gql`
  query getProfileDocuments($userId: Int!) {
    documents: getProfileDocuments(userId: $userId) {
      id
      file {
        id
        keyName
        fileName
        mimeType
      }
    }
  }
`;

export const getProcessDocuments = gql`
  query processDocuments($processId: String!) {
    documents: processDocuments(processId: $processId) {
      id
      file {
        id
        keyName
        fileName
        mimeType
      }
    }
  }
`;

export const getProcessNoteDocuments = gql`
  query processNoteDocuments($processNoteIds: [Int!]!) {
    documents: processNoteDocuments(processNoteIds: $processNoteIds) {
      id
      processNoteId
      file {
        id
        keyName
        fileName
        mimeType
      }
    }
  }
`;

export const getProcessNoteDocumentFileUrl = gql`
  query processNoteDocumentFileUrl($processNoteId: Int!, $fileId: String!) {
    url: processNoteDocumentFileUrl(processNoteId: $processNoteId, fileId: $fileId)
  }
`;

export const getProcessDocumentFileUrl = gql`
  query processDocumentFileUrl($processId: String!, $fileId: String!) {
    url: processDocumentFileUrl(processId: $processId, fileId: $fileId)
  }
`;

export const getProfileDocumentFileUrl = gql`
  query getProfileDocumentFileUrl($userId: Int!, $fileId: String!) {
    url: getProfileDocumentFileUrl(userId: $userId, fileId: $fileId)
  }
`;

export const getProfileFileUrl = gql`
  query getProfileFileUrl($userId: Int!) {
    url: getProfileFileUrl(userId: $userId)
  }
`;

export const getProfileBusinessFileUrl = gql`
  query getProfileBusinessFileUrl($userId: Int!) {
    url: getProfileBusinessFileUrl(userId: $userId)
  }
`;

export const getSignedUrlForFacilityDocument = gql`
  query getSignedUrlForFacilityDocument($facilityId: Int, $keyName: String!, $mimeType: String!) {
    getSignedUrlForFacilityDocument(facilityId: $facilityId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForFacilityFile = gql`
  query getSignedUrlForFacilityFile($facilityId: Int!, $keyName: String!, $mimeType: String!) {
    getSignedUrlForFacilityFile(facilityId: $facilityId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const getSignedUrlForFacilityFileAsTechnician = gql`
  query getSignedUrlForFacilityFileAsTechnician(
    $facilityUserId: Int!
    $facilityId: Int!
    $keyName: String!
    $mimeType: String!
    $signature: String!
  ) {
    getSignedUrlForFacilityFileAsTechnician(
      facilityUserId: $facilityUserId
      facilityId: $facilityId
      keyName: $keyName
      signature: $signature
      mimeType: $mimeType
    )
  }
`;

export const fetchFacilityFileUrl = gql`
  query fetchFacilityFileUrl($facilityId: Int!) {
    url: fetchFacilityFileUrl(facilityId: $facilityId)
  }
`;

export const getContractDocumentFileUrl = gql`
  query contractDocumentFileUrl($contractId: String!, $fileId: String!) {
    url: contractDocumentFileUrl(contractId: $contractId, fileId: $fileId)
  }
`;

export const signedUrlForProcessDocument = gql`
  query signedUrlForProcessDocument($processId: String!, $keyName: String!, $mimeType: String!) {
    signedUrlForProcessDocument(processId: $processId, keyName: $keyName, mimeType: $mimeType)
  }
`;

export const signedUrlForProcessNoteDocument = gql`
  query signedUrlForProcessNoteDocument($processNoteId: Int!, $keyName: String!, $mimeType: String!) {
    signedUrlForProcessNoteDocument(processNoteId: $processNoteId, keyName: $keyName, mimeType: $mimeType)
  }
`;

// mutations
// TODO:A - remove (backend part was removed)
export const storeDeviceDocument = gql`
  mutation storeDeviceDocument($deviceId: Int!, $fileName: String!, $keyName: String!, $mimeType: String!) {
    storeDeviceDocument(deviceId: $deviceId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeDeviceDocumentByTechnician = gql`
  mutation storeDeviceDocumentByTechnician(
    $facilityUserId: Int!
    $deviceId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
    $signature: String!
  ) {
    storeDeviceDocumentByTechnician(
      facilityUserId: $facilityUserId
      deviceId: $deviceId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
      signature: $signature
    ) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

// TODO: probably should be removed, because we have 'storeFacilityDocumentNew'
export const storeFacilityDocument = gql`
  mutation storeFacilityDocument(
    $facilityId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeFacilityDocument(
      facilityId: $facilityId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
    ) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeFacilityFile = gql`
  mutation storeFacilityFile($facilityId: Int!, $fileName: String!, $keyName: String!, $mimeType: String!) {
    storeFacilityFile(facilityId: $facilityId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeFacilityFileByTechnician = gql`
  mutation storeFacilityFileByTechnician(
    $facilityUserId: Int!
    $signature: String!
    $facilityId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeFacilityFileByTechnician(
      facilityUserId: $facilityUserId
      signature: $signature
      facilityId: $facilityId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
    ) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeFacilityDocumentByTechnician = gql`
  mutation storeFacilityDocumentByTechnician(
    $facilityUserId: Int!
    $signature: String!
    $facilityId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeFacilityDocumentByTechnician(
      facilityUserId: $facilityUserId
      signature: $signature
      facilityId: $facilityId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
    ) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeDeviceFile = gql`
  mutation storeDeviceFile(
    $facilityUserId: Int
    $signature: String
    $deviceId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeDeviceFile(
      facilityUserId: $facilityUserId
      signature: $signature
      deviceId: $deviceId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
    ) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeTaskFileInfo = gql`
  mutation storeTaskFileInfo($taskId: String!, $fileName: String!, $keyName: String!, $mimeType: String!) {
    storeTaskFileInfo(taskId: $taskId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeRunTaskFileInfo = gql`
  mutation storeRunTaskFileInfo(
    $runTaskId: String!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeRunTaskFileInfo(runTaskId: $runTaskId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeProfileFile = gql`
  mutation storeProfileFile($userId: Int!, $fileName: String!, $keyName: String!, $mimeType: String!) {
    storeProfileFile(userId: $userId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeProfileBusinessFile = gql`
  mutation storeProfileBusinessFile(
    $userId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeProfileBusinessFile(userId: $userId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeProfileDocument = gql`
  mutation storeProfileDocument($userId: Int!, $fileName: String!, $keyName: String!, $mimeType: String!) {
    storeProfileDocument(userId: $userId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const storeLogFile = gql`
  mutation storeLogFile($userId: Int!, $fileName: String!, $keyName: String!, $mimeType: String!) {
    storeLogFile(userId: $userId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
    }
  }
`;

export const storeProcessDocument = gql`
  mutation storeProcessDocument(
    $processId: String!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeProcessDocument(processId: $processId, fileName: $fileName, keyName: $keyName, mimeType: $mimeType) {
      id
    }
  }
`;

export const storeProcessNoteDocument = gql`
  mutation storeProcessNoteDocument(
    $processNoteId: Int!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeProcessNoteDocument(
      processNoteId: $processNoteId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
    ) {
      id
    }
  }
`;

export const storeContractDocument = gql`
  mutation storeContractDocument(
    $facilityUserId: Int!
    $technicianUserId: Int!
    $contractId: String!
    $fileName: String!
    $keyName: String!
    $mimeType: String!
  ) {
    storeContractDocument(
      facilityUserId: $facilityUserId
      technicianUserId: $technicianUserId
      contractId: $contractId
      fileName: $fileName
      keyName: $keyName
      mimeType: $mimeType
    ) {
      id
      fileName
      keyName
      mimeType
    }
  }
`;

export const deleteTaskFile = gql`
  mutation deleteTaskFile($taskId: String!, $fileId: String!) {
    deleteTaskFile(taskId: $taskId, fileId: $fileId)
  }
`;

export const deleteDeviceDocument = gql`
  mutation deleteDeviceDocument($deviceId: Int!, $documentId: Int!) {
    deleteDeviceDocument(deviceId: $deviceId, documentId: $documentId)
  }
`;

export const deleteFacilityDocument = gql`
  mutation deleteFacilityDocument($documentId: Int!) {
    deleteFacilityDocument(documentId: $documentId)
  }
`;

export const deleteProfileFile = gql`
  mutation deleteProfileFile($userId: Int!) {
    deleteProfileFile(userId: $userId)
  }
`;

export const deleteProfileBusinessFile = gql`
  mutation deleteProfileBusinessFile($userId: Int!) {
    deleteProfileBusinessFile(userId: $userId)
  }
`;

export const deleteProfileDocument = gql`
  mutation deleteProfileDocument($userId: Int!, $documentId: Int!) {
    deleteProfileDocument(userId: $userId, documentId: $documentId)
  }
`;

export const deleteContractDocument = gql`
  mutation deleteContractDocument($documentId: Int!) {
    deleteContractDocument(documentId: $documentId)
  }
`;

export const deleteProcessDocument = gql`
  mutation deleteProcessDocument($documentId: Int!) {
    deleteProcessDocument(documentId: $documentId)
  }
`;

export const deleteProcessNoteDocument = gql`
  mutation deleteProcessNoteDocument($documentId: Int!) {
    deleteProcessNoteDocument(documentId: $documentId)
  }
`;
