import { alphabeticalSorting } from '@uptime/shared/utils/serviceProviders';
import qs from 'qs';

export const getEmployeeOptions = (facilityUsersList, companyData) => {
  const options = facilityUsersList.map((item) => ({
    value: item.userId,
    label: item.firstName + ' ' + item.lastName,
  }));
  options.push({
    value: companyData?.userData?.userId,
    label: companyData?.userData?.firstName + ' ' + companyData?.userData?.lastName,
  });
  return options.sort(alphabeticalSorting('label'));
};

export const parseSettings = (payload) => {
  return qs.parse(payload, { delimiter: ';', allowDots: true });
};
