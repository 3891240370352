import { createContext, useState } from 'react';
import * as PropTypes from 'prop-types';

import { FEATURES } from '@uptime/shared/constants/permissions';

const initialState = {
  permissions: [],
  addPermissionsFromToken: () => null,
  cleanupPermissions: () => null,
};

export const PermissionsContext = createContext(initialState);

const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(initialState.permissions);
  const [hasPermissionsLoaded, setHasPermissionsLoaded] = useState(false);

  const addPermissionsFromToken = (tokenPayload) => {
    const features = tokenPayload.features || [];

    if (features.includes(FEATURES.BULK_UPDATE)) {
      setPermissions((state) => [...state, FEATURES.BULK_UPDATE]);
    }

    if (features.includes(FEATURES.BULK_IMPORT)) {
      setPermissions((state) => [...state, FEATURES.BULK_IMPORT]);
    }

    if (features.includes(FEATURES.RESET_TASKS)) {
      setPermissions((state) => [...state, FEATURES.RESET_TASKS]);
    }

    if (features.includes(FEATURES.TECHNICIAN_MANAGEMENT)) {
      setPermissions((state) => [...state, FEATURES.TECHNICIAN_MANAGEMENT]);
    }
  };

  const cleanupPermissions = () => {
    setPermissions(initialState.permissions);
  };

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        hasPermissionsLoaded,
        setPermissions,
        setHasPermissionsLoaded,
        addPermissionsFromToken,
        cleanupPermissions,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
};

export default PermissionsProvider;
