import { gql } from '@apollo/client';

export const getAccountProfile = gql`
  query getProfile($userId: Int!) {
    profile: getProfile(userId: $userId) {
      userId
      accountId
      firstName
      lastName
      mobile
      image
      step
      lastLogin
      baseUser {
        email
        profile {
          firstName
          lastName
        }
      }
      topLevelEmail
      topLevelFirstName
      topLevelLastName
    }
  }
`;

export const getBusinessCards = gql`
  query businessCards($userIds: [Int!]!) {
    cards: businessCards(userIds: $userIds) {
      userId
      firstName
      lastName
      mobile
      businessName
      businessEmail
      businessPhone
      linkedIn
      description
      companySite
    }
  }
`;

export const getBusinessProfile = gql`
  query getBusinessProfile($accountId: Int!) {
    businessProfile: getBusinessProfile(accountId: $accountId) {
      accountId
      name
      phone
      email
      image
      site
      linkedIn
      description
      companyTypeId
      companyType {
        id
        title
      }
      customerType {
        id
        alias
        title
      }
    }
  }
`;

export const getUserProfile = gql`
  query getProfile($userId: Int!) {
    userData: getProfile(userId: $userId) {
      userId
      accountId
      firstName
      lastName
      mobile
      image
      baseUser {
        email
      }
      step
      userGroupId
    }
  }
`;

export const getContact = gql`
  query fetchProfile($userId: Int!) {
    profile: getProfile(userId: $userId) {
      userId
      firstName
      lastName
      baseUser {
        email
      }
    }
  }
`;

export const getUserAddress = gql`
  query getUserAddress($userId: Int!, $isBusiness: Boolean!) {
    address: getUserAddress(userId: $userId, isBusiness: $isBusiness) {
      id
      userId
      isBusiness
      addressLine
      addressLineTwo
      city
      state
      zipCode
    }
  }
`;

export const getFacilityUser = gql`
  query getFacilityUser($id: Int!) {
    getFacilityUser(id: $id) {
      username
      email
      profile {
        firstName
        lastName
        mobile
      }
    }
  }
`;

export const getUserSettings = gql`
  query getUserSettings($userId: Int!) {
    settings: getUserSettings(userId: $userId) {
      userId
      settingId
      isActive
      alias
      title
      position
      type
      value
    }
  }
`;

export const getUserDashboards = gql`
  {
    getUserDashboards {
      id
      title
    }
  }
`;

export const getUserInvoiceDetails = gql`
  query getUserInvoiceDetails($userId: Int!) {
    invoice: getUserInvoiceDetails(userId: $userId) {
      userId
      addressLine
      addressLineTwo
      city
      state
      zipCode
      attention
      isBusinessAddress
    }
  }
`;

// TODO:H:4
export const getUserStripeAccount = gql`
  query userStripeAccount($userId: Int!) {
    stripeAccount: userStripeAccount(userId: $userId) {
      accountId
    }
  }
`;

export const getAccountGroups = gql`
  query accountGroups {
    accountGroups {
      groupId
      name
    }
  }
`;

export const getCompanyTypes = gql`
  query getCompanyTypes($userId: Int!) {
    companyTypes: getCompanyTypes(userId: $userId) {
      value: id
      label: title
    }
  }
`;

export const getCustomerTypes = gql`
  query getCustomerTypes($userId: Int!) {
    getCustomerTypes(userId: $userId) {
      value: id
      label: title
      alias
    }
  }
`;

// Mutations

export const whetherUserExists = gql`
  query whetherUserExists($email: String!) {
    isUserExists: whetherUserExists(email: $email)
  }
`;

export const onUpdateProfile = gql`
  mutation updateProfile($userId: Int!, $profileData: UpdateProfileInput!) {
    updateProfile(userId: $userId, profileData: $profileData) {
      userId
      firstName
      lastName
      mobile
      image
      step
      baseUser {
        email
      }
    }
  }
`;

export const updateBusinessProfile = gql`
  mutation updateBusinessProfile($businessProfileData: UpdateBusinessProfileInput!) {
    updateBusinessProfile(businessProfileData: $businessProfileData) {
      accountId
      email
      image
      description
      name
      phone
      linkedIn
      customerTypeId
      companyTypeId
      companyType {
        id
        title
      }
      customerType {
        alias
        id
        title
      }
    }
  }
`;

export const onUpdateAddress = gql`
  mutation updateUserAddress($userAddressData: UpdateUserAddressInput!) {
    updateUserAddress(userAddressData: $userAddressData)
  }
`;

export const onCreateUserAddress = gql`
  mutation createUserAddress($userId: Int!, $userAddressData: CreateUserAddressInput!) {
    createUserAddress(userId: $userId, userAddressData: $userAddressData) {
      id
      userId
      isBusiness
      addressLine
    }
  }
`;

export const onUpdateUserSettings = gql`
  mutation updateUserSettings($userId: Int!, $settings: [UserSettingInput]!) {
    updateUserSettings(userId: $userId, settings: $settings)
  }
`;

export const onSaveUserInvoice = gql`
  mutation saveUserInvoiceDetails($userId: Int!, $invoiceData: UserInvoiceDetailsInput!) {
    saveUserInvoiceDetails(userId: $userId, invoiceData: $invoiceData)
  }
`;

export const onCreateFacilityUser = gql`
  mutation createFacilityUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $teamId: Int!
    $memberships: [String!]
  ) {
    createFacilityUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      teamId: $teamId
      memberships: $memberships
    )
  }
`;

export const onSaveStripeAccountData = gql`
  mutation saveStripeAccountData($userId: Int!, $payload: stripeOauthCallbackPayload!) {
    saveStripeAccountData(userId: $userId, payload: $payload)
  }
`;

export const getImpersonatedToken = gql`
  query getImpersonatedToken($signature: String!, $identificator: String!) {
    token: getImpersonatedToken(signature: $signature, identificator: $identificator)
  }
`;

export const signInAsUnifiedImagingUser = gql`
  mutation signInAsUnifiedImagingUser($token: String!) {
    token: signInAsUnifiedImagingUser(token: $token)
  }
`;

export const getTechnicianTeam = gql`
  query getTechnicianTeam(
    $pagination: PaginationInput
    $sortBy: [TechnicianTeamSortInput!]
    $filter: TechnicianTeamFilterInput!
    $searchBy: String
  ) {
    team: getTechnicianTeam(pagination: $pagination, sortBy: $sortBy, filter: $filter, searchBy: $searchBy) {
      hits {
        id
        fullName
        email
        status
        type
        userId
        signature
        createdAt
        phone
        companyName
        teamTitle
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const createTechnicianUser = gql`
  mutation createTechnicianUser($email: String!, $firstName: String!, $lastName: String!, $teamId: Int!) {
    createTechnicianUser(email: $email, firstName: $firstName, lastName: $lastName, teamId: $teamId)
  }
`;

export const getRoles = gql`
  query getRoles {
    roles {
      id
      title
      alias
    }
  }
`;

export const removeContractorFromTechnicianTeam = gql`
  mutation removeContractorFromTechnicianTeam($id: String!) {
    removeContractorFromTechnicianTeam(id: $id)
  }
`;

export const getTechnicianTeamMember = gql`
  query getTechnicianTeamMember($userId: Int!, $type: String!) {
    technicianTeamMember: getTechnicianTeamMember(userId: $userId, type: $type) {
      id
      status
    }
  }
`;

export const inviteContractorToTechnicianTeam = gql`
  mutation inviteContractorToTechnicianTeam($userId: Int!) {
    inviteContractorToTechnicianTeam(userId: $userId)
  }
`;

export const acceptContractorInviteToTechnicianTeam = gql`
  mutation acceptContractorInviteToTechnicianTeam($token: String!) {
    acceptContractorInviteToTechnicianTeam(token: $token)
  }
`;

export const declineContractorInviteToTechnicianTeam = gql`
  mutation declineContractorInviteToTechnicianTeam($token: String!) {
    declineContractorInviteToTechnicianTeam(token: $token)
  }
`;

export const resendContractorInviteToTechnicianTeam = gql`
  mutation resendContractorInviteToTechnicianTeam($id: String!) {
    resendContractorInviteToTechnicianTeam(id: $id)
  }
`;

export const setLastLogin = gql`
  mutation setLastLogin($userId: Int!) {
    setLastLogin(userId: $userId)
  }
`;

export const getStaffTeams = gql`
  query getStaffTeams($accountId: Int!) {
    getStaffTeams(accountId: $accountId) {
      value: id
      alias
      label: title
    }
  }
`;

export const getTeamList = gql`
  query groupList($filter: GroupFilterInput) {
    groupList(filter: $filter) {
      hits {
        value: id
        label: name
        description
      }
    }
  }
`;

export const getFacilityUsersList = gql`
  query getFacilityUsersList($pagination: PaginationInput, $sortBy: [UsersSortInput!], $searchBy: String) {
    getFacilityUsersList(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy) {
      hits {
        email
        firstName
        image
        lastLogin
        lastName
        mobile
        teamId
        teamTitle
        userId
        username
        providerGroupNames
      }
      itemsCount
      pageCount
    }
  }
`;

export const updateUserGroups = gql`
  mutation updateUserGroups($userId: Int!, $addGroupIds: [Int], $deleteGroupIds: [Int]) {
    updateUserGroups(userId: $userId, addGroupIds: $addGroupIds, deleteGroupIds: $deleteGroupIds)
  }
`;

export const sendTechnicianReference = gql`
  mutation SendTechnicianReference($firstName: String!, $lastName: String!, $email: String!) {
    sendTechnicianReference(firstName: $firstName, lastName: $lastName, email: $email)
  }
`;

export const getStudentMemberships = gql`
  query GetStudentMemberships($getStudentMembershipsId: Int!) {
    getStudentMemberships(id: $getStudentMembershipsId) {
      enrolled
      id
      title
    }
  }
`;

export const updateStudentMemberships = gql`
  mutation UpdateStudentMemberships($userId: Int!, $memberships: [StudentMembershipToUpdate!]!) {
    updateStudentMemberships(userId: $userId, memberships: $memberships)
  }
`;
