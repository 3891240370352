import React, { createContext, useCallback, useContext, useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @ts-ignore
import { useIdleTimer } from 'react-idle-timer/legacy';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import Splash from '@uptime/shared/components/Splash';
import { COGNITO_KEYS, LAST_LOGIN } from '@uptime/shared/constants';
import { clearAuthData } from '@uptime/shared/utils/general';

// @ts-ignore
import * as cognito from 'libs/services/cognito';
import { useOnMount, useOnUpdate } from 'libs/hooks';

type Context = {
  signIn: () => Promise<any>;
  signOut: () => Promise<void>;
  resetPassword: (username: string, code: string, password: string) => Promise<any>;
  forgotPassword: (username: string) => Promise<any>;
  confirmPassword: (username: string, code: string, password: string) => Promise<any>;
  isAuthorized: boolean;
  changePassword: (username: string, oldPassword: string, newPassword: string) => Promise<any>;
  getLastLoginPath: () => string;
  getUser: () => Record<any, any> | undefined;
};

type Props = {
  children: React.ReactNode;
};

type CognitoError = {
  code: string;
  name: string;
};

export const CognitoContext = createContext<Context>({
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  resetPassword: async (username: string, code: string, password: string) => {
    console.log(username, code, password);
  },
  forgotPassword: async (username: string) => {
    console.log(username);
  },
  confirmPassword: async (username: string, code: string, password: string) => {
    console.log(username, code, password);
  },
  isAuthorized: false,
  changePassword: async (username: string, oldPassword: string, newPassword: string) => {},
  getLastLoginPath: () => '',
  getUser: () => ({}),
});

export const useCognito = () => useContext(CognitoContext);

const getPayloads = (session) => ({
  accessTokenPayload: session.accessToken.payload,
  idTokenPayload: session.idToken.payload,
});

const getTokens = (session) => ({
  accessToken: session.accessToken.jwtToken,
  refreshToken: session.refreshToken.token,
  idToken: session.idToken.jwtToken,
});

const setTokensInStorage = ({ accessToken, refreshToken, idToken }) => {
  localStorage.setItem(COGNITO_KEYS.ACCESS_TOKEN, accessToken);
  localStorage.setItem(COGNITO_KEYS.ID_TOKEN, idToken);
  localStorage.setItem(COGNITO_KEYS.REFRESH_TOKEN, refreshToken);
};

const setPayloadsInStorage = ({ idTokenPayload, accessTokenPayload }) => {
  localStorage.setItem(COGNITO_KEYS.ACCESS_TOKEN_PAYLOAD, JSON.stringify(accessTokenPayload));
  localStorage.setItem(COGNITO_KEYS.ID_TOKEN_PAYLOAD, JSON.stringify(idTokenPayload));
};

const storeTokensInStorage = (session) => setTokensInStorage(getTokens(session));
const storePayloadsInStorage = (session) => setPayloadsInStorage(getPayloads(session));

const resetStorage = () => Object.values(COGNITO_KEYS).forEach((key: any) => localStorage.removeItem(key));

const setLastLoginInformation = (lastLoginInfo: { pathname: string; userId?: string }) =>
  localStorage.setItem(
    LAST_LOGIN,
    JSON.stringify({
      ...lastLoginInfo,
      updatedAt: Date.now(),
    })
  );
const resetLastLoginInformation = () => localStorage.removeItem(LAST_LOGIN);

const storeDetailsInStorage = (session: CognitoUserSession) => {
  resetStorage();
  [storeTokensInStorage, storePayloadsInStorage].map((f) => f(session));
};

const ERROR_MESSAGE = {
  INVALID_USERNAME_OR_PASSWORD: 'Incorrect username or password.',
  OOPS: 'Oops, something went wrong.',
  RATE_LIMIT: 'Rate limit exceeded. Please retry later.',
  INVALID_VERIFICATION_CODE_OR_EMAIL: 'Invalid verification code or username.',
  CANNOT_RESET_USER_PASSWORD: 'Forbidden! Please contact our support.',
};

const COGNITO_ERROR = {
  LimitExceededException: 'LimitExceededException',
  UserNotFoundException: 'UserNotFoundException',
  CodeMismatchException: 'CodeMismatchException',
  NotAuthorizedException: 'NotAuthorizedException',
  InvalidParameterException: 'InvalidParameterException',
  ExpiredCodeException: 'ExpiredCodeException',
};

const ERROR_MAPPER = {
  // triggered when rate limit is reached
  [COGNITO_ERROR.LimitExceededException]: ERROR_MESSAGE.RATE_LIMIT,
  // triggered when user does not exist
  [COGNITO_ERROR.UserNotFoundException]: ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD,
  // triggered when user code does not match aws cognito code
  [COGNITO_ERROR.CodeMismatchException]: ERROR_MESSAGE.INVALID_VERIFICATION_CODE_OR_EMAIL,
  // triggered when user is disabled or email address is invalid
  [COGNITO_ERROR.NotAuthorizedException]: ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD,
  // triggered when email is not verified or invalid verification code
  [COGNITO_ERROR.InvalidParameterException]: ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD,
  // triggered when code expired
  [COGNITO_ERROR.ExpiredCodeException]: ERROR_MESSAGE.INVALID_VERIFICATION_CODE_OR_EMAIL,
};

const getError = (error: CognitoError) => {
  const message = ERROR_MAPPER[error.code];

  if (message) {
    return message;
  }

  return ERROR_MESSAGE.OOPS;
};

const DEFAULT_PATH = '/app';
const ONE_MINUTE = 60 * 1000;
const IDLE_TIMEOUT = ONE_MINUTE * 60;

const IGNORE_LAST_LOGIN_PATHS = ['/app/login', '/app/activation', '/app/callback', '/app/forbidden'];

export const CognitoProvider = ({ children }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const intervalRef = useRef(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  const { start, pause } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onAction: () => {},
    onActive: () => {},
    onIdle: async () => {
      resetLastLoginInformation();
      resetStorage();
      setIsAuthorized(false);
      setSession(null);

      clearAuthData();

      pause();

      return cognito.signOut();
    },
    debounce: 1000,
    startManually: true,
  });

  useOnMount(() => {
    async function setUserSession() {
      let session: CognitoUserSession;
      setIsAuthorizing(true);

      try {
        session = await cognito.getUserSession();
      } catch (e) {
        setIsAuthorizing(false);
        console.log('[error]: failed attempt to get user session');
        return;
      }

      storeDetailsInStorage(session);
      setSession(session);
      setIsAuthorized(true);
      setIsAuthorizing(false);
      start();
    }

    !isAuthorized && !isAuthorizing && setUserSession();
  });

  useOnUpdate(() => {
    if (!session || isAuthorizing) return;

    const scheduleRefresh = () => {
      try {
        const expiration = session.getIdToken().getExpiration();

        // @ts-ignore
        intervalRef.current = setTimeout(
          async () => {
            try {
              console.log('[info]: about to renew user session');
              const session = await cognito.refreshSession();

              if (!Boolean(session)) {
                console.log("[error]: cannot renew user's session, session is empty, signing out");
                setIsAuthorized(false);
                return;
              }

              storeDetailsInStorage(session);
              setSession(session);
              setIsAuthorized(true);
              console.log('[info]: user session has been refreshed successfully');
            } catch (e) {
              console.log("[error]: cannot renew user's session, signing out");
              setIsAuthorized(false);
            }
          },
          // prettier-ignore
          expiration * 1000 - (ONE_MINUTE * 5) - Date.now()
        );
      } catch (e) {
        console.log('[error]: schedule refresh, signing out');
        setIsAuthorized(false);
      }
    };

    scheduleRefresh();

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [session?.getAccessToken().getExpiration()]);

  useEffect(() => {
    if (IGNORE_LAST_LOGIN_PATHS.includes(location.pathname) || isSigningOut) return;

    const { userId } = session?.getIdToken()?.payload || {};

    setLastLoginInformation({
      userId,
      pathname: `${location.pathname}${location.search}`,
    });
  }, [location, session?.getAccessToken().getExpiration()]);

  const signOut = useCallback(async () => {
    setIsSigningOut(true);

    let session;

    try {
      session = await cognito.getUserSession();
    } catch (e) {}

    resetLastLoginInformation();
    resetStorage();
    setIsAuthorized(false);
    setSession(null);

    pause();
    setIsSigningOut(false);

    if (Boolean(session)) {
      return cognito.signOut();
    }

    history.push('/app/login');
  }, []);

  const resetPassword = useCallback(async (username, oldPassword, newPassword) => {
    try {
      const session = await cognito.resetPassword(username, oldPassword, newPassword);

      storeDetailsInStorage(session);
      setSession(session);
    } catch (e) {
      console.log('[error]: failed attempt to reset password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const forgotPassword = useCallback(async (username: string) => {
    try {
      await cognito.forgotPassword(username);
    } catch (e) {
      console.log('[error]: failed attempt to forgot password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const confirmPassword = useCallback(async (username: string, code: string, password: string) => {
    try {
      await cognito.confirmPassword(username, code, password);
    } catch (e) {
      console.log('[error]: failed attempt to confirm password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const changePassword = useCallback(async (username: string, oldPassword: string, newPassword: string) => {
    try {
      await cognito.changePassword(username, oldPassword, newPassword);
    } catch (e) {
      console.log('[error]: failed attempt to change password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const signIn = useCallback(cognito.signIn, []);

  const getLastLoginPath = useCallback(() => {
    const activeUser = session?.getIdToken()?.payload;

    if (!activeUser) return DEFAULT_PATH;

    const lastLogin = JSON.parse(localStorage.getItem(LAST_LOGIN) || `{ "pathname": "${DEFAULT_PATH}" }`);

    if (!lastLogin.userId || activeUser.userId === lastLogin.userId) {
      // to show job details we should set active wo to local storage first
      // and this is exactly what we do in /app/request-email
      if (lastLogin.pathname.includes('/app/work-orders/request-view')) {
        return lastLogin.pathname.replace('work-orders/request-view', 'request-email');
      }

      return lastLogin.pathname || DEFAULT_PATH;
    }

    return DEFAULT_PATH;
  }, [session?.getAccessToken().getExpiration()]);

  const getUser = useCallback(
    () => session?.getIdToken()?.payload,
    [session?.getAccessToken().getExpiration()]
  );

  if (isAuthorizing) {
    return <Splash open />;
  }

  return (
    <CognitoContext.Provider
      value={{
        isAuthorized,
        signOut,
        signIn,
        resetPassword,
        forgotPassword,
        confirmPassword,
        changePassword,
        getLastLoginPath,
        getUser,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};
