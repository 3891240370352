import { gql } from '@apollo/client';

// Fragments
const PAGINATION_FRAGMENT = `
    fragment runTaskPagination on RunTasksPayload {
        pageCount
        currentPage
        itemsCount
    }
`;

const COMPLIANCE_SETTING_FRAGMENT = `
    fragment complianceSettingsFragment on ComplianceColumnSetting {
        settingId
        isActive
        title
        position
        alias
    }
`;

const RUN_TASK_FRAGMENT = `
fragment runTaskInfo on RunTask {
    id
    taskId
    status
    scheduleTime
    startTime
    note
    isCorrected
    completedBy
    createdAt
    updatedAt
    fileId
    fileUrl
    completedBy
    completedByInitials
    startOn
    task {
       id
       type
       ownerId
       areaId
       facilityId
       assignedUserId
       taskCategoryId
       group
       repeatType
       schedulerTime
       description
       deviceId
       name
       externalLink
       isRequiredNote
       isRequiredSubTaskNotes
       isRequiredImage
       shouldCloseAutomatically
    }
    runSubTasksCounter {
       all
       done
    }
    runSubTasks {
       id
       runTaskId
       subTask {
          id
          name
          note
          taskId
          position
       }
    }
}
`;

const TASK_INFO_FRAGMENT = `
fragment taskInfo on TaskPayload {
    id
    name
    type
    schedulerTime
}
`;

const TASK_INFO_FRAGMENT_V2 = `
fragment taskInfo on TaskPayload {
      id
      schedulerTime
      name
      startOn
      days
      type
      taskCategoryId
      description
      facilityId
      deviceId
      areaId
      assignedUserId
      group
      ownerId
      repeatType
      isRequiredNote
      isRequiredSubTaskNotes
      isRequiredImage
      externalLink
      shouldCloseAutomatically
      useSchedule
      logTypeId
      subTasks {
        id
        name
        taskId
        position
      }
    }
`;

// Mutations
export const updateComplianceColumnSettingsMutation = gql`
  mutation updateComplianceColumnSettings(
    $userId: Int!
    $tabType: RunTaskStatus!
    $settings: [ComplianceColumnSettingInput]!
  ) {
    updateComplianceColumnSettings(userId: $userId, tabType: $tabType, settings: $settings)
  }
`;

export const sendComplianceReportEmail = gql`
  mutation sendComplianceReportEmail(
    $to: [String!]!
    $cc: [String!]
    $userId: Int!
    $status: RunTaskStatus!
    $filter: FilterComplianceEmailInput!
    $sortBy: [RunTaskSortInput!]
  ) {
    sendComplianceReportEmail(
      to: $to
      cc: $cc
      userId: $userId
      status: $status
      filter: $filter
      sortBy: $sortBy
    )
  }
`;

export const onSaveTask = gql`
  ${TASK_INFO_FRAGMENT_V2}

  mutation createTask(
    $name: String!
    $description: String!
    $facilityId: Int!
    $deviceId: Int
    $areaId: Int
    $assignedUserId: Int
    $group: String
    $type: TaskType!
    $taskCategoryId: Int!
    $repeatType: Int!
    $startOn: Int!
    $days: TaskDaysInput!
    $isRequiredNote: Boolean
    $isRequiredSubTaskNotes: Boolean
    $isRequiredImage: Boolean
    $shouldCloseAutomatically: Boolean
    $externalLink: String
    $subTasks: [SubTaskInput]
    $useSchedule: Boolean
    $schedule: ScheduleInput
    $nonWorkingDays: [NonWorkingDay]
    $logTypeId: String
  ) {
    createTask(
      name: $name
      description: $description
      deviceId: $deviceId
      facilityId: $facilityId
      areaId: $areaId
      taskCategoryId: $taskCategoryId
      assignedUserId: $assignedUserId
      group: $group
      type: $type
      repeatType: $repeatType
      startOn: $startOn
      days: $days
      isRequiredNote: $isRequiredNote
      isRequiredSubTaskNotes: $isRequiredSubTaskNotes
      isRequiredImage: $isRequiredImage
      shouldCloseAutomatically: $shouldCloseAutomatically
      externalLink: $externalLink
      subTasks: $subTasks
      useSchedule: $useSchedule
      schedule: $schedule
      nonWorkingDays: $nonWorkingDays
      logTypeId: $logTypeId
    ) {
      ...taskInfo
    }
  }
`;

export const onUpdateTask = gql`
  ${TASK_INFO_FRAGMENT_V2}
  mutation updateTask(
    $id: String!
    $name: String!
    $description: String!
    $facilityId: Int!
    $taskCategoryId: Int!
    $deviceId: Int
    $areaId: Int
    $assignedUserId: Int
    $group: String
    $type: TaskType!
    $repeatType: Int!
    $startOn: Int!
    $isRequiredNote: Boolean
    $isRequiredSubTaskNotes: Boolean
    $isRequiredImage: Boolean
    $shouldCloseAutomatically: Boolean
    $externalLink: String
    $days: TaskDaysInput!
    $useSchedule: Boolean
    $schedule: ScheduleInput
    $nonWorkingDays: [NonWorkingDay]
    $logTypeId: String
  ) {
    updateTask(
      id: $id
      name: $name
      description: $description
      deviceId: $deviceId
      facilityId: $facilityId
      taskCategoryId: $taskCategoryId
      areaId: $areaId
      assignedUserId: $assignedUserId
      group: $group
      type: $type
      repeatType: $repeatType
      startOn: $startOn
      isRequiredNote: $isRequiredNote
      isRequiredSubTaskNotes: $isRequiredSubTaskNotes
      isRequiredImage: $isRequiredImage
      shouldCloseAutomatically: $shouldCloseAutomatically
      externalLink: $externalLink
      days: $days
      useSchedule: $useSchedule
      schedule: $schedule
      nonWorkingDays: $nonWorkingDays
      logTypeId: $logTypeId
    ) {
      ...taskInfo
    }
  }
`;

export const onUpdateRunSubTask = gql`
  mutation updateRunSubTask($id: String!, $isDone: Boolean!, $note: String) {
    updateRunSubTask(id: $id, isDone: $isDone, note: $note) {
      id
      runTaskId
      subTaskId
      subTask {
        id
        name
        taskId
      }
      isDone
      note
    }
  }
`;

export const onUpdateRunTask = gql`
  mutation updateRunTask(
    $id: String!
    $startTime: Int
    $note: String
    $status: RunTaskStatus!
    $isCorrected: Boolean!
    $completedByInitials: String
    $logId: String
  ) {
    updateRunTask(
      id: $id
      startTime: $startTime
      note: $note
      status: $status
      isCorrected: $isCorrected
      completedByInitials: $completedByInitials
      logId: $logId
    ) {
      id
      taskId
      startTime
      note
      status
      isCorrected
      completedByInitials
      logId
    }
  }
`;

export const onResetRunTask = gql`
  mutation resetRunTask($id: String!) {
    resetRunTask(id: $id) {
      id
      taskId
      startTime
      note
      status
      isCorrected
      completedByInitials
    }
  }
`;

export const createTaskFromExisting = gql`
  ${TASK_INFO_FRAGMENT_V2}
  mutation createTaskFromExisting($id: String!, $type: TaskType!) {
    createTaskFromExisting(id: $id, type: $type) {
      ...taskInfo
    }
  }
`;

export const onDeleteTask = gql`
  mutation deleteTask($id: String!) {
    deleteTask(id: $id)
  }
`;

export const onUpdateSubTasks = gql`
  mutation updateSubTasks($taskId: String!, $subTasks: [SubTaskUpdateInput]) {
    updateSubTasks(taskId: $taskId, subTasks: $subTasks)
  }
`;

// Queries
export const getTaskCategories = gql`
  query taskCategories($userId: Int!) {
    taskCategories(userId: $userId) {
      value: id
      label: title
      alias
    }
  }
`;

// TODO:H:5 - PROD-6907
export const getTasksV2 = gql`
  ${TASK_INFO_FRAGMENT_V2}
  query tasks(
    $userId: Int!
    $filter: FilterTaskInput!
    $pagination: PaginationInput
    $search: String
    $sortBy: [TaskSortInput!] = { field: name, order: ASC }
  ) {
    tasks(userId: $userId, filter: $filter, pagination: $pagination, search: $search, sortBy: $sortBy) {
      hits {
        ...taskInfo
      }
      currentPage
      pageCount
      itemsCount
    }
  }
`;

export const getAllTasks = gql`
  ${TASK_INFO_FRAGMENT}
  query getTasks(
    $userId: Int!
    $pagination: PaginationInput!
    $dailyFilter: FilterTaskInput!
    $weeklyFilter: FilterTaskInput!
    $monthlyFilter: FilterTaskInput!
  ) {
    daily: tasks(userId: $userId, pagination: $pagination, filter: $dailyFilter) {
      hits {
        ...taskInfo
      }
    }
    weekly: tasks(userId: $userId, pagination: $pagination, filter: $weeklyFilter) {
      hits {
        ...taskInfo
      }
    }
    monthly: tasks(userId: $userId, pagination: $pagination, filter: $monthlyFilter) {
      hits {
        ...taskInfo
      }
    }
  }
`;

// TODO:H:6 - PROD-6907 ??? check if runTasks ALSO will be accountId dependent
// check other 'query runTasks'
export const getInProgressTasksCounts = gql`
  query runTasks($userId: Int!, $filter: FilterRunTaskInput) {
    inProgress: runTasks(
      userId: $userId
      status: in_progress
      forOwner: false
      pagination: { page: 1 }
      overdue: false
      filter: $filter
    ) {
      itemsCount
    }
    overdue: runTasks(
      userId: $userId
      status: in_progress
      forOwner: false
      pagination: { page: 1 }
      overdue: true
      filter: $filter
    ) {
      itemsCount
    }
  }
`;

export const getRunTasksV2 = gql`
  query runTasks(
    $userId: Int!
    $status: RunTaskStatus!
    $pagination: PaginationInput!
    $overdue: Boolean
    $forOwner: Boolean = false
    $filter: FilterRunTaskInput
    $sortBy: [RunTaskSortInput!]
    $search: String
  ) {
    runTasks(
      userId: $userId
      status: $status
      forOwner: $forOwner
      pagination: $pagination
      overdue: $overdue
      filter: $filter
      sortBy: $sortBy
      search: $search
    ) {
      hits {
        id
        taskId
        status
        scheduleTime
        startTime
        note
        isCorrected
        completedBy
        createdAt
        updatedAt
        fileId
        fileUrl
        completedBy
        completedByInitials
        startOn
        logId
        task {
          id
          type
          taskCategoryId
          ownerId
          areaId
          facilityId
          assignedUserId
          group
          repeatType
          days
          schedulerTime
          description
          deviceId
          name
          externalLink
          isRequiredNote
          isRequiredSubTaskNotes
          isRequiredImage
          shouldCloseAutomatically
          logTypeId
        }
        runSubTasksCounter {
          all
          done
        }
        runSubTasks {
          id
          runTaskId
          subTask {
            id
            name
            note
            taskId
            position
          }
        }
      }
      itemsCount
    }
  }
`;

export const onFetchRunSubTasks = gql`
  query runSubTasks($runTaskId: String!) {
    subTasks: runSubTasks(runTaskId: $runTaskId) {
      id
      isDone
      note
      runTaskId
      note
      isDone
      subTask {
        id
        name
      }
    }
  }
`;

export const getRunTask = gql`
  ${RUN_TASK_FRAGMENT}
  query runTask($id: String!) {
    runTask(id: $id) {
      ...runTaskInfo
    }
  }
`;

// TODO:H:6
export const goCheckItRunTasksCompliance = gql`
  ${PAGINATION_FRAGMENT}
  query runTasks(
    $userId: Int!
    $forOwner: Boolean!
    $overdue: Boolean
    $pagination: PaginationInput!
    $filter: FilterRunTaskInput
    $filterForInProgress: FilterRunTaskInput
    $status: RunTaskStatus!
    $sortBy: [RunTaskSortInput!]
    $search: String
  ) {
    in_progress: runTasks(
      userId: $userId
      status: in_progress
      forOwner: $forOwner
      pagination: $pagination
      filter: $filterForInProgress
      search: $search
    ) {
      ...runTaskPagination
    }
    complete: runTasks(
      userId: $userId
      status: complete
      forOwner: $forOwner
      pagination: $pagination
      filter: $filter
      search: $search
    ) {
      ...runTaskPagination
    }
    incomplete: runTasks(
      userId: $userId
      status: incomplete
      forOwner: $forOwner
      pagination: $pagination
      filter: $filter
      search: $search
    ) {
      ...runTaskPagination
    }
    runTasks(
      userId: $userId
      status: $status
      overdue: $overdue
      forOwner: $forOwner
      pagination: $pagination
      filter: $filter
      sortBy: $sortBy
      search: $search
    ) {
      hits {
        id
        startTime
        scheduleTime
        status
        note
        completedBy
        completedByInitials
        fileUrl
        fileId
        isCorrected
        logId
        updatedAt
        createdAt
        runSubTasksCounter {
          all
          done
        }
        runSubTasks {
          subTask {
            name
          }
          note
        }
        task {
          id
          schedulerTime
          assignedUserId
          description
          name
          type
          taskCategoryId
          areaId
          deviceId
          facilityId
          group
          externalLink
          isRequiredNote
          isRequiredSubTaskNotes
          isRequiredImage
          logTypeId
          subTasks {
            id
            name
          }
        }
      }
      ...runTaskPagination
    }
  }
`;

export const getComplianceColumnSettings = gql`
  ${COMPLIANCE_SETTING_FRAGMENT}
  query complianceColumnSettings($userId: Int!) {
    in_progress: complianceColumnSettings(userId: $userId, tabType: in_progress) {
      ...complianceSettingsFragment
    }
    complete: complianceColumnSettings(userId: $userId, tabType: complete) {
      ...complianceSettingsFragment
    }
    incomplete: complianceColumnSettings(userId: $userId, tabType: incomplete) {
      ...complianceSettingsFragment
    }
  }
`;

export const calculateScheduledTime = gql`
  query calculateScheduledTime($type: TaskType!, $repeatType: Int!, $startOn: Int!, $days: TaskDaysInput!) {
    calculateScheduledTime(type: $type, repeatType: $repeatType, startOn: $startOn, days: $days) {
      schedulerDateTime
      runTime
    }
  }
`;

export const calculateTimeUsingFacilitySchedule = gql`
  query calculateTimeUsingFacilitySchedule(
    $type: TaskType!
    $repeatType: Int!
    $startOn: Int!
    $days: TaskDaysInput!
    $facilityId: Int!
    $schedule: ScheduleInput!
    $nonWorkingDays: [NonWorkingDay]!
  ) {
    calculateTimeUsingFacilitySchedule(
      type: $type
      repeatType: $repeatType
      startOn: $startOn
      days: $days
      facilityId: $facilityId
      schedule: $schedule
      nonWorkingDays: $nonWorkingDays
    ) {
      schedulerDateTime
      runTime
    }
  }
`;

// TODO:H:5 - PROD-6907
export const getCalendarTasks = gql`
  query tasks($userId: Int!, $pagination: PaginationInput, $filter: FilterTaskInput!) {
    tasks(userId: $userId, pagination: $pagination, filter: $filter) {
      hits {
        id
        name
        description
        facilityId
        deviceId
        areaId
        taskCategoryId
        assignedUserId
        group
        ownerId
        type
        schedulerTime
        startOn
        days
        repeatType
        externalLink
        isRequiredNote
        isRequiredSubTaskNotes
        isRequiredImage
        subTasks {
          id
          name
          taskId
        }
      }
      currentPage
      pageCount
      itemsCount
    }
  }
`;

export const sendComplianceReportEmailWithAttachedExcel = gql`
  mutation sendComplianceReportEmailWithAttachedExcel(
    $to: [String!]!
    $cc: [String!]
    $userId: Int!
    $status: RunTaskStatus!
    $overdue: Boolean
    $forOwner: Boolean!
    $filter: FilterRunTaskInput!
    $sortBy: [RunTaskSortInput!]
    $timezoneOffset: Int!
  ) {
    sendComplianceReportEmailWithAttachedExcel(
      to: $to
      cc: $cc
      userId: $userId
      status: $status
      overdue: $overdue
      forOwner: $forOwner
      filter: $filter
      sortBy: $sortBy
      timezoneOffset: $timezoneOffset
    )
  }
`;

export const sendDeviceTaskHistoryReportEmailWithAttachedExcel = gql`
  mutation sendDeviceTaskHistoryReportEmailWithAttachedExcel(
    $to: [String!]!
    $cc: [String!]
    $fullName: String!
    $timezoneOffset: Int!
    $accountId: Int!
  ) {
    sendDeviceTaskHistoryReportEmailWithAttachedExcel(
      to: $to
      cc: $cc
      fullName: $fullName
      timezoneOffset: $timezoneOffset
      accountId: $accountId
    )
  }
`;

export const sendFacilityTaskHistoryReportEmailWithAttachedExcel = gql`
  mutation sendFacilityTaskHistoryReportEmailWithAttachedExcel(
    $to: [String!]!
    $cc: [String!]
    $fullName: String!
    $timezoneOffset: Int!
    $accountId: Int!
  ) {
    sendFacilityTaskHistoryReportEmailWithAttachedExcel(
      to: $to
      cc: $cc
      fullName: $fullName
      timezoneOffset: $timezoneOffset
      accountId: $accountId
    )
  }
`;

export const tasksBulkUpdate = gql`
  mutation tasksBulkUpdate(
    $payload: [TaskUpdateInput!]!
    $field: BulkTaskUpdateField!
    $value: String!
    $facilityId: Int
    $filter: FilterTaskInput
    $search: String
  ) {
    tasksBulkUpdate(
      payload: $payload
      field: $field
      value: $value
      facilityId: $facilityId
      filter: $filter
      search: $search
    )
  }
`;
