import { createContext } from 'react';

const AuthContext = createContext({
  userId: null,
  role: null,
  email: null,
  accountId: null,
  type: null,
  isRepairer: false,
  isManager: false,
  isFacilityUser: false,
  isTopLevel: false,
  isTopLevelFacility: false,
  isTopLevelRepairer: false,
  setFacilityData: (payload = {}) => null,
});

export default AuthContext;
