import { useQuery } from '@apollo/client';
import { getBusinessProfile } from '@uptime/shared/graphql/users';
import { userClient } from 'apolloClientConfiguration';

interface Props {
  accountId: string | null;
}

const useCompanyData = ({ accountId }: Props) => {
  const { data: { businessProfile } = {} } = useQuery(getBusinessProfile, {
    variables: {
      accountId,
    },
    skip: !Boolean(accountId),
    client: userClient,
    fetchPolicy: 'cache-first',
  });

  const companySiteUrl = Boolean(businessProfile?.site) ? JSON.parse(businessProfile?.site) : null;
  const businessName = businessProfile?.name ?? null;

  return { companySiteUrl, businessName };
};

export default useCompanyData;
