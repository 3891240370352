import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import uuid from 'uuid';

import { DAYS_OPTIONS, NOTIFICATION_LABELS, WIZARD_PAGES } from '@uptime/shared/constants/account';
// TODO: should be moved to general
import {
  business,
  documents,
  documentType,
  notifications,
  facilityPayments,
  repairPayments,
  personal,
  team,
  teamList,
} from '../components/Account/wizardSteps';
import {
  FACILITY_ROLE,
  FACILITY_STAFF_ROLE,
  REPAIRER_ROLE,
  STICKY_FACILITY_FILTER_SETTING,
  SHOULD_SENT_SMS,
} from '@uptime/shared/constants';

export const getStepsForRole = (role, permissions, isDocumentManager, hasConfigurableSettings) => {
  const { facilityUsersManagementPermission, techAccountManagementPermission } = permissions;
  const isTechSub = role === REPAIRER_ROLE && !techAccountManagementPermission;

  if (isTechSub) {
    return [
      // wizard steps
      [personal],
      // elements/steps
      pick(WIZARD_PAGES, [WIZARD_PAGES.PERSONAL_INFORMATION]),
      // wizard steps
      pick(WIZARD_PAGES, [WIZARD_PAGES.PERSONAL_INFORMATION]),
      // account edit headers
      [personal],
    ];
  }

  switch (role) {
    case FACILITY_ROLE:
      return [
        // wizard headers
        [
          personal,
          business,
          ...(facilityUsersManagementPermission ? [team] : []),
          facilityPayments,
          ...(hasConfigurableSettings ? [notifications] : []),
        ],
        // elements/steps
        pick(WIZARD_PAGES, [
          WIZARD_PAGES.PERSONAL_INFORMATION,
          WIZARD_PAGES.BUSINESS_INFORMATION,
          ...(facilityUsersManagementPermission ? [WIZARD_PAGES.TEAM] : []),
          WIZARD_PAGES.FACILITY_PAYMENTS,
          ...(isDocumentManager ? [WIZARD_PAGES.DOCUMENT_TYPES] : []),
          ...(hasConfigurableSettings ? [WIZARD_PAGES.NOTIFICATIONS] : []),
          WIZARD_PAGES.TEAM_LIST,
        ]),
        // wizard steps
        pick(WIZARD_PAGES, [
          WIZARD_PAGES.PERSONAL_INFORMATION,
          WIZARD_PAGES.BUSINESS_INFORMATION,
          ...(facilityUsersManagementPermission ? [WIZARD_PAGES.TEAM] : []),
          WIZARD_PAGES.FACILITY_PAYMENTS,
          ...(hasConfigurableSettings ? [WIZARD_PAGES.NOTIFICATIONS] : []),
        ]),
        // account edit headers
        [
          personal,
          business,
          ...(facilityUsersManagementPermission ? [team] : []),
          facilityPayments,
          ...(isDocumentManager ? [documentType] : []),
          ...(hasConfigurableSettings ? [notifications] : []),
          teamList,
        ],
      ];
    case REPAIRER_ROLE:
      return [
        // wizard headers
        [personal, business, documents, repairPayments, ...(hasConfigurableSettings ? [notifications] : [])],
        // elements/steps
        pick(WIZARD_PAGES, [
          WIZARD_PAGES.PERSONAL_INFORMATION,
          WIZARD_PAGES.BUSINESS_INFORMATION,
          WIZARD_PAGES.DOCUMENTS,
          WIZARD_PAGES.REPAIR_PAYMENTS,
          ...(hasConfigurableSettings ? [WIZARD_PAGES.NOTIFICATIONS] : []),
        ]),
        // wizard steps
        pick(WIZARD_PAGES, [
          WIZARD_PAGES.PERSONAL_INFORMATION,
          WIZARD_PAGES.BUSINESS_INFORMATION,
          WIZARD_PAGES.DOCUMENTS,
          WIZARD_PAGES.REPAIR_PAYMENTS,
          ...(hasConfigurableSettings ? [WIZARD_PAGES.NOTIFICATIONS] : []),
        ]),
        // account edit headers
        [personal, business, documents, repairPayments, ...(hasConfigurableSettings ? [notifications] : [])],
      ];
    case FACILITY_STAFF_ROLE:
    default:
      return [
        // wizard headers
        [personal, ...(hasConfigurableSettings ? [notifications] : [])],
        // elements/steps
        pick(WIZARD_PAGES, [
          WIZARD_PAGES.PERSONAL_INFORMATION,
          ...(isDocumentManager ? [WIZARD_PAGES.DOCUMENT_TYPES] : []),
          ...(hasConfigurableSettings ? [WIZARD_PAGES.NOTIFICATIONS] : []),
        ]),
        // wizard steps
        pick(WIZARD_PAGES, [
          WIZARD_PAGES.PERSONAL_INFORMATION,
          ...(hasConfigurableSettings ? [WIZARD_PAGES.NOTIFICATIONS] : []),
        ]),
        // account edit headers
        [
          personal,
          ...(isDocumentManager ? [documentType] : []),
          ...(hasConfigurableSettings ? [notifications] : []),
        ],
      ];
  }
};

export const getNormalisedTextForSettings = (key) => NOTIFICATION_LABELS[key] || key;

export const getPreparedSettings = (data) => {
  if (data) {
    const values = Object.values(data);
    const settings = values.reduce((accumulator, current) => accumulator.concat(current), []);

    return settings.map((item) => omit(item, 'title'));
  }
  return [];
};

export const getImageToSave = (file) =>
  file && {
    id: uuid.v4(),
    file,
  };

export const mapProfileData = (data) => ({
  ...pick(data, ['firstName', 'lastName', 'mobile', 'step']),
  ...(data.image ? { image: null } : {}),
  ...(data.step ? { step: data.step } : {}),
  ...(data.businessName ? { businessName: data.businessName } : {}),
});

export const notificationsMapper = (isManager = false, isTopLevelRepairer = false) => {
  const defaultNotifications = isManager
    ? {}
    : {
        emailDeviceAssigned: true,
        isMpSearchable: true,
      };

  return {
    ...defaultNotifications,
    emailCurrentMonthPm: true,
    emailDownDevice: true,
    emailMonthlyDeviceMaintenance: true,
    emailDailyRunTask: true,
    emailDailyRunTaskOverdue: true,
    runTaskReportDaily: true,
    dailyTaskCompletionReportForManager: true,
    dailyTaskOverdueReportForSubAccounts: !isManager,
    dailyTaskOverdueReportForManager: true,
    initialsRequiredToCompleteTasks: true,
    allowToShareWONotes: isTopLevelRepairer,
  };
};

export const getDaysLabel = (days = []) => {
  const dayNames = days
    .map((item, index) => {
      if (isString(item)) {
        const day = DAYS_OPTIONS.find(({ value }) => value === item);

        return day && day.label;
      }

      return DAYS_OPTIONS[index] && DAYS_OPTIONS[index].label;
    })
    .filter((item) => Boolean(item));

  return dayNames.join(', ');
};

export const checkIsNotEmptySettings = (settings = []) =>
  settings.filter(({ title }) => title !== STICKY_FACILITY_FILTER_SETTING).length > 0;

export const removeShouldSendSMS = (data) => {
  const newData = Object.assign({}, data);
  newData.notification = newData?.notification.filter(({ title }) => title !== SHOULD_SENT_SMS);

  return newData;
};

export const checkIfHasConfigurableSettings = (settings = []) => {
  return (
    settings.filter(({ title }) => {
      return ![STICKY_FACILITY_FILTER_SETTING, SHOULD_SENT_SMS].includes(title);
    }).length > 0
  );
};
