import { createContext } from 'react';
import createPersistedReducer from 'use-persisted-reducer';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

const initialState = {
  profile: undefined,
  users: undefined,
  isLoading: undefined,
  accountFlows: [],
  activeWO: undefined,
  activeBusinessTask: undefined,
  businessTaskVariables: undefined,
  accountGroups: undefined,
  taskCategories: [],
  whiteLabel: undefined,
  businessProfile: undefined,
};

const usePersistedReducer = createPersistedReducer('source');
export const SourceContext = createContext(initialState);

const SET_TASK_CATEGORIES = 'SET_TASK_CATEGORIES';
const SET_PROFILE = 'SET_PROFILE';
const SET_BUSINESS_PROFILE = 'SET_BUSINESS_PROFILE';
const SET_ACCOUNT_GROUPS = 'SET_ACCOUNT_GROUPS';
const SET_SOURCE_IS_LOADING = 'SET_SOURCE_IS_LOADING';
const SET_ACCOUNT_FLOWS = 'SET_ACCOUNT_FLOWS';
const SET_ACTIVE_WO = 'SET_ACTIVE_WO';
const SET_ACTIVE_BUSINESS_TASK = 'SET_ACTIVE_BUSINESS_TASK';
const SET_BUSINESS_TASK_VARIABLES = 'SET_BUSINESS_TASK_VARIABLES';
const SET_WHITE_LABEL_SETTINGS = 'SET_WHITE_LABEL_SETTINGS';

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PROFILE:
      return { ...state, profile: payload.profile };
    case SET_BUSINESS_PROFILE:
      return { ...state, businessProfile: payload.businessProfile };
    case SET_ACCOUNT_GROUPS:
      return {
        ...state,
        accountGroups: payload.accountGroups.map((item) => ({
          value: item.name,
          label: item.name,
        })),
      };
    case SET_TASK_CATEGORIES:
      const len = payload.taskCategories.length;
      const last = len - 1;

      const sorted = _.chain(payload.taskCategories)
        .slice(0, last)
        .sortBy((category) => category.label)
        .value();
      const taskCategories = [...sorted, payload.taskCategories[last]];

      return {
        ...state,
        taskCategories,
      };
    case SET_SOURCE_IS_LOADING:
      return { ...state, isLoading: payload.isLoading };
    case SET_ACTIVE_WO:
      return { ...state, activeWO: payload.workOrder };
    case SET_ACTIVE_BUSINESS_TASK:
      return { ...state, activeBusinessTask: payload.businessTask };
    case SET_BUSINESS_TASK_VARIABLES:
      return {
        ...state,
        businessTaskVariables: payload.businessTaskVariables,
      };
    case SET_ACCOUNT_FLOWS:
      return { ...state, accountFlows: payload.accountFlows };
    case SET_WHITE_LABEL_SETTINGS:
      return { ...state, whiteLabel: payload.whiteLabel };
    default:
      return state;
  }
}

const SourceProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = usePersistedReducer(reducer, initialState);

  const setActiveWO = (workOrder) =>
    dispatch({
      type: SET_ACTIVE_WO,
      payload: { workOrder },
    });

  const setProfile = (profile) =>
    dispatch({
      type: SET_PROFILE,
      payload: { profile },
    });

  const setBusinessProfile = (businessProfile) =>
    dispatch({
      type: SET_BUSINESS_PROFILE,
      payload: { businessProfile },
    });

  const setAccountFlows = (accountFlows) =>
    dispatch({
      type: SET_ACCOUNT_FLOWS,
      payload: { accountFlows },
    });

  const setActiveBusinessTask = (businessTask) =>
    dispatch({
      type: SET_ACTIVE_BUSINESS_TASK,
      payload: { businessTask },
    });

  const setAccountGroups = (accountGroups) =>
    dispatch({
      type: SET_ACCOUNT_GROUPS,
      payload: { accountGroups },
    });

  const setBusinessTaskVariables = (businessTaskVariables) =>
    dispatch({
      type: SET_BUSINESS_TASK_VARIABLES,
      payload: { businessTaskVariables },
    });

  const setTaskCategories = (taskCategories) =>
    dispatch({
      type: SET_TASK_CATEGORIES,
      payload: { taskCategories },
    });

  const setWhiteLabel = (whiteLabel) =>
    dispatch({
      type: SET_WHITE_LABEL_SETTINGS,
      payload: { whiteLabel },
    });

  return (
    <SourceContext.Provider
      value={{
        ...state,
        setProfile,
        setBusinessProfile,
        setAccountFlows,
        setActiveWO,
        setActiveBusinessTask,
        setAccountGroups,
        setBusinessTaskVariables,
        setTaskCategories,
        setWhiteLabel,
      }}
    >
      {children}
    </SourceContext.Provider>
  );
};

SourceProvider.propTypes = {
  children: PropTypes.node,
};

export default SourceProvider;
