export const TECHNICIAN_TEAM_LIST_HEADER = [
  {
    id: 'fullName',
    isSortable: true,
    label: 'Full Name',
    isVisible: true,
    style: { minWidth: 'auto', paddingLeft: 24 },
  },
  { id: 'email', isSortable: true, label: 'Email', isVisible: true },
  { id: 'status', isSortable: true, label: 'Status', isVisible: true },
  { id: 'role', isSortable: true, label: 'Role', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto' },
  },
];

export const CREATE_STATE_NAME = 'serviceProviderCreationState';
