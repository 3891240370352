import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import * as PropTypes from 'prop-types';
import WrapLink from '@uptime/shared/components/WrapLink';

import UserAvatar from '@uptime/shared/components/UserAvatar';

import styles from './styles';

const UserMenu = (props) => {
  const { fullName, userEmail, userAvatarUrl, logout } = props;
  const classes = styles();

  return (
    <Box className={classes.root}>
      <Box position="relative">
        <Box className={classes.userSection}>
          <Avatar>
            <UserAvatar src={userAvatarUrl} name={fullName} />
          </Avatar>
          <Box style={{ marginLeft: 7 }}>
            <Box component="h4" m={0}>
              {fullName}
            </Box>
            <p className={classes.userEmail}>{userEmail}</p>
          </Box>
        </Box>
        <Box className={classes.navigationSection}>
          <List style={{ padding: 0 }}>
            <ListItem button component={WrapLink} to="/app/settings/account" data-testid="account">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
            <ListItem button onClick={logout} data-testid="logout">
              <ListItemIcon className={classes.logoutSection}>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

UserMenu.propTypes = {
  fullName: PropTypes.string,
  userEmail: PropTypes.string,
  userAvatarUrl: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

export default UserMenu;
